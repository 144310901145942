<template>
    <div class="editorAttr">
        <div class="header flex">
            <div class="flex-1">
                <div style="width:120px;text-align:center;">选项</div>
            </div>
            <!-- <div v-if="popTitle == '颜色'" class="flex-1">描述</div> -->
            <div style="width:130px;text-align:center;">操作</div>
        </div>
        <!-- <div class="content">
            <div v-if="list && list.length>0" class="list">
                <div v-for="(item,idx) in list" :key="idx" class="flex">
                    <div v-if="popTitle == '颜色'" class="flex-1">
                        <SelectColors v-model="item.value"></SelectColors>
                    </div>
                    <div class="flex-1">
                        <el-input style="width:90px;margin-right:5px;" maxlength="8" size="mini" v-model="item.name" placeholder="请输入内容"></el-input>
                    </div>
                    <div style="width:100px;text-align:center;">
                        <el-button @click="delClass(idx)" type="text" size="mini">删除</el-button>
                    </div>
                </div>
            </div>
        </div> -->
        <div v-if="list && list.length>0" class="content" ref="load">
            <draggable class="list-group" v-model="list"
                @change="changeSort"
                v-bind="dragOptions" tag="ul">
                <transition-group type="transition" name="flip-list">
                    <li v-for="(item,idx) in list" :key="idx" aria-hidden="true" class="list-group-item flex">
                        <div class="flex-1">
                            <el-input @change="editSubmit(item,idx)" :disabled="listId!=item.id" style="width:120px;margin-right:5px;" maxlength="8" size="mini" v-model="item.name" placeholder="请输入内容"></el-input>
                            <el-button v-if="listId==item.id" style="margin-left:10px;" @click="editSubmit(item,idx)" type="text" size="mini">确定</el-button>
                            <el-button v-if="listId!=item.id" style="margin-left:10px;" @click="editClass(item,idx)" type="text" size="mini">修改</el-button>
                        </div>
                        <div style="width:100px;text-align:center;">
                            <el-button @click="delClass(item,idx)" type="text" size="mini">删除</el-button>
                        </div>
                    </li>
                </transition-group>
                
            </draggable>
        </div>
        <div class="add flex" style="padding: 20px 0px 10px 30px;">
            <!-- <div v-if="popTitle == '颜色'" style="margin-right:5px;">
                <SelectColors v-model="colorValue"></SelectColors>
            </div> -->
            <div><el-input style="width:120px;margin-right:5px;" size="mini" v-model="createdName" placeholder="请输入内容"></el-input></div>
            <div><el-button size="mini" @click="addClass()" type="primary">确认</el-button></div>
            <div class="font14-grey1" style="line-height:28px;margin-left:15px;color:red">可拖拽排序</div>
        </div>
        <!-- <div style="padding-top:20px;">
            <el-button type="primary" size="small" @click="submit()" style="width: 80px;">提交</el-button>
        </div> -->

    </div>
</template>
<script>
    import draggable from 'vuedraggable';
    import { 
        styleList, styleAdd, styleEdit, styleDel, styleSort,
        colorList, colorAdd, colorEdit, colorDel, colorSort,
        materialList, materialAdd, materialEdit, materialDel, materialSort,
    } from '@/service/dictionary';
    // import SelectColors from '@/components/SelectColors.vue';
    export default {
        components:{
            // SelectColors,
            draggable
        },
        props: ['popTitle'],
        data() {
            return {
                list:"",
                listId:null,
                createdName:"",
                colorValue:"",
            }
        },
        watch:{
            popTitle:{
                handler() {
                    this.refreshItems();
                },
                deep: true
            }
        },
        computed: {
            dragOptions() {
                return {
                    animation: 100,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        created() {
            this.refreshItems();
        },
        mounted() {},
        methods: {
            addClass() {
                // if (this.createdName) {
                //     if (this.popTitle == "颜色" && this.colorValue) {
                //         this.list.push({
                //             name:this.createdName,
                //             value:this.colorValue,
                //         });
                //         this.colorValue = "";
                //     } else {
                //         this.list.push({
                //             name:this.createdName,
                //         });
                //     }
                //     this.createdName = "";
                // }
                if (!this.createdName) {
                    return this.$message.error("请输入内容");
                }
                let length = 0;
                if (this.list && this.list.length>0) {
                    length = this.list.length;
                }
                if (this.popTitle=="风格") {
                    styleAdd({name:this.createdName,order:length}).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle=="颜色") {
                    colorAdd({name:this.createdName,order:length}).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle=="材质") {
                    materialAdd({name:this.createdName,order:length}).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },
            changeSort(e) {
                let sortList = [];
                this.list.forEach((e,idx) => {
                    sortList.push({ id:e.id, order:idx })
                });
                if (this.popTitle=="风格") {
                    styleSort(sortList).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle=="颜色") {
                    colorSort(sortList).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle=="材质") {
                    materialSort(sortList).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },
            editClass(item,index) {
                this.listId = item.id;
            },
            editSubmit(item,index) {
                let obj = {
                    id:item.id,
                    name:item.name,
                }
                if (!item.name) {
                    return this.$message.error("请输入内容");
                }
                if (this.popTitle=="风格") {
                    styleEdit(obj).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle=="颜色") {
                    colorEdit(obj).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle=="材质") {
                    materialEdit(obj).then(rst => {
                        this.refreshItems();
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
                this.listId = null;
            },
            delClass(item,index) {
                this.$confirm('确认删除?', '温馨提示', { confirmButtonText: '确定',
                cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    if (this.popTitle=="风格") {
                        styleDel(item.id).then(rst => {
                            this.$message.success("删除成功");
                            this.refreshItems();
                        }).catch(err => {
                            this.$message.error(err.message);
                        });
                    } else if (this.popTitle=="颜色") {
                        colorDel(item.id).then(rst => {
                            this.$message.success("删除成功");
                            this.refreshItems();
                        }).catch(err => {
                            this.$message.error(err.message);
                        });
                    } else if (this.popTitle=="材质") {
                        materialDel(item.id).then(rst => {
                            this.$message.success("删除成功");
                            this.refreshItems();
                        }).catch(err => {
                            this.$message.error(err.message);
                        });
                    }
                });
            },
            refreshItems() {
                console.log('refreshItems');
                const _loading = this.$loading({
                    lock: true,
                    target:this.$refs['load'],
                    text: '拼命加载中......',
                });
                if (this.popTitle == "风格") {
                    styleList().then(rst => {
                        this.list=rst;
                        _loading.close();
                    }).catch(err => {
                        _loading.close();
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle == "颜色") {
                    colorList().then(rst => {
                        _loading.close();
                        this.list=rst;
                    }).catch(err => {
                        _loading.close();
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle == "材质") {
                    materialList().then(rst => {
                        _loading.close();
                        this.list=rst;
                    }).catch(err => {
                        _loading.close();
                        this.$message.error(err.message);
                    });
                }
                console.log(this.list);
            },
            submit() {
                // if (this.popTitle == "风格") {
                //     styleSave(this.list).then(rst => {
                //         this.refreshItems();
                //         this.$emit("hide");
                //         this.$message.success("添加成功");
                //     }).catch(err => {
                //         this.$message.error(err.message);
                //     });
                // } else if (this.popTitle == "颜色") {
                //     colorSave(this.list).then(rst => {
                //         this.refreshItems();
                //         this.$emit("hide");
                //         this.$message.success("添加成功");
                //     }).catch(err => {
                //         this.$message.error(err.message);
                //     });
                // } else if (this.popTitle == "材质") {
                //     materialSave(this.list).then(rst => {
                //         this.refreshItems();
                //         this.$emit("hide");
                //         this.$message.success("添加成功");
                //     }).catch(err => {
                //         this.$message.error(err.message);
                //     });
                // }
            },
        }
    }
</script>
<style scoped>
    .header {
        padding:0 30px;
        border: 1px solid #EBEEF5;
    }
    .header>div {
        font-weight: 600;
        color: #000;
        line-height:38px;
    }
    .content {
        padding: 10px 0;
        border: 1px solid #EBEEF5;
        border-top: none;
    }
    .content .list-group {
        max-height: 320px;
        overflow-y: auto;
        padding: 0 20px;
    }
    .content .list-group .list-group-item {
        padding: 8px 10px;
        cursor: pointer;
        border-bottom: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
        border-radius: 5px;
    }
    .content .list-group .list-group-item:first-child {
        border-top: 1px solid #EBEEF5;
    }
    .ghost {
        opacity: 0.5;
        background: #c8ebfb7a;
    }
    /* .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    } */
    /* .list-group-item {
        cursor: move;
    } */
</style>