<template>
    <div style="height:100%">
        <div class="page-header">产品销售属性字典</div>
        <div class="block" style="height: calc(100% - 159px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="id" label="ID" width="120">
                    </el-table-column>
                    <el-table-column prop="name" label="字段名称">
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="edit(scope.row,scope.$index)">
                                <span class="icon iconfont icon-bianji1"></span>&nbsp;编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            
        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <EditAttr :popTitle="popTitle" @hide="popVisible=false"></EditAttr>
        </el-dialog>
    </div>
</template>
<script>
    import { styleList,colorList,materialList } from '@/service/dictionary';
    import EditAttr from './EditAttr.vue';
    export default {
        components: {
            EditAttr,
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                list:[],
            }
        },
        created() {
            this.list = [
                {id:1,name:"风格"},
                {id:2,name:"颜色"},
                {id:3,name:"材质"},
            ];
        },
        mounted() {},
        methods: {
            edit(item,index) {
                if (index == 0) {
                    this.popTitle = "风格";
                } else if (index == 1) {
                    this.popTitle = "颜色";
                } else if (index == 2) {
                    this.popTitle = "材质";
                }
                this.popVisible = true;
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .icon {
        font-size: 13px;
    }
</style>